import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 90px 0",
	"quarkly-title": "Headline-4"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"lg-flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"width": "50%",
			"font": "--headline2",
			"padding": "0px 50px 0px 0px",
			"lg-width": "100%",
			"lg-margin": "0px 0px 50px 0px",
			"md-padding": "0px 0 0px 0px",
			"md-font": "normal 500 42px/1.2 --fontFamily-serifTimes",
			"sm-margin": "0px 0px 35px 0px",
			"children": "Завітайте до нас сьогодні!"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "50%",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"lg-width": "100%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "--lead",
			"color": "--dark",
			"sm-margin": "0px 0px 25px 0px",
			"children": "Відчуйте відмінності CycleCare Pro. Привезіть свій велосипед до нас і дозвольте нашим фахівцям подбати про нього. Ваш велосипед заслуговує найкращого, і в CycleCare Pro це саме те, що ми пропонуємо."
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" sm-min-width="280px" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Box {...override("box1")}>
				<Text {...override("text1")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;