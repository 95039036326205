import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Strong, Box, Link, Section, Em } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"background": "#0c1128",
	"quarkly-title": "Footer-11",
	"md-padding": "40px 0 40px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-width": "100%",
			"flex-direction": "row",
			"lg-flex-direction": "row",
			"justify-content": "space-between",
			"width": "100%",
			"padding": "0 0px 0 0px",
			"md-padding": "0 0px 30px 0px",
			"lg-padding": "0 0px 50px 0px",
			"sm-flex-direction": "column",
			"md-flex-direction": "column",
			"md-display": "grid",
			"md-grid-gap": "54px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"lg-margin": "0px 0px 0px 0px",
			"width": "30%",
			"display": "flex",
			"lg-width": "50%",
			"sm-width": "100%",
			"flex-direction": "column",
			"padding": "0px 50px 0px 0px",
			"sm-margin": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0 0px 0 0px",
			"font": "--headline3",
			"color": "#c3c8d0",
			"sm-text-align": "left",
			"sm-margin": "25px 0px 20px 0px",
			"children": "OakBridge Legal Solutions"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "40px 0px 35px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"sm-text-align": "left",
			"sm-margin": "25px 0px 20px 0px",
			"children": <Em>
				Gerechtigkeit stärken, Seelenfrieden fördern
			</Em>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "36px 34px",
			"lg-align-items": "start",
			"md-grid-template-columns": "repeat(4, 1fr)",
			"md-grid-gap": "36px 34px",
			"sm-grid-gap": "36px 34px",
			"sm-grid-template-columns": "repeat(2, 1fr)",
			"width": "60%"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"flex-wrap": "wrap",
			"align-content": "start",
			"grid-gap": "8px 0",
			"lg-justify-content": "start"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "--light",
			"children": "Navigation"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "/index",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#a78bfa",
			"children": "Hauptseite"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"href": "/services",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#a78bfa",
			"children": "Dienstleistungen"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#a78bfa",
			"href": "/faq",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"children": "Kontakte"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"flex-wrap": "wrap",
			"align-content": "start",
			"grid-gap": "8px 0",
			"lg-justify-content": "start"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "--light",
			"children": "Kontakte"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "#",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#a78bfa",
			"children": "Reichenberger Str. 120, 10999 Berlin, Germany"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#a78bfa",
			"href": "+493023598880",
			"children": "+493023598880"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#a78bfa",
			"href": "#",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"children": "contact@solovirex.com"
		}
	}
};

const Header2 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<Box {...override("box3")}>
					<Text {...override("text2")} />
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
				</Box>
				<Box {...override("box4")}>
					<Text {...override("text3")} />
					<Link {...override("link3")} />
					<Link {...override("link4")} />
					<Link {...override("link5")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header2, { ...Section,
	defaultProps,
	overrides
});
export default Header2;